import { Link } from '@inertiajs/react';

export default function Guest({ children }) {
  return (
    <div className="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-indigo-200">
      <div>
        <Link href="/">
          <header className="pt-5">
            <span className="text-4xl font-bold mb-4 text-teal-600 font-serif">linkblend</span>
          </header>
        </Link>
      </div>

      <div className=" w-full sm:max-w-md mt-6 p-10 bg-zinc-50 shadow-md overflow-hidden sm:rounded-lg">{children}</div>

      <div className="mt-10">
        <a href="/" className="text-md mb-4 text-teal-600 font-serif">
          back to homepage
        </a>
      </div>
    </div>
  );
}
